import React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import Seo from 'components/SEO'
import PageContent from 'components/Shared/_PageContent'
import Title from 'components/Shared/Title'
import ProgramNav from 'components/Public/ProgramNav'

import styled from 'styled-components'
const Content = styled.section`
  width: var(--paragraph-width);
  --text-align: left;

  h2 {
    color: var(--color-primary);
    margin: calc(var(--margin-default)*1.5) 0 0;
    &:first-child {
      margin-top: 0;
    }
  }
  h4 {
    margin-bottom: var(--margin-default);
  }
  p {
    --text-align: left;
  }
`

const Camps = ({ data, location }) => {
  const {
    content: { body },
    seo,
  } = data
  const { title, description } = seo.frontmatter.programs

  return (
    <PageContent>
      <Seo title={title} description={description} />
      <ProgramNav location={location} />
      <Title>晶晶營隊</Title>
      <Content>
        <MDXRenderer>{body}</MDXRenderer>
      </Content>
    </PageContent>
  )
}

export default Camps

export const query = graphql`
  {
    content: mdx(frontmatter: { type: { eq: "page" }, slug: { eq: "camps" } }) {
      body
    }

    seo: mdx(frontmatter: { type: { eq: "seo" } }) {
      frontmatter {
        programs {
          title
          description
        }
      }
    }
  }
`
